/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-06-10",
    versionChannel: "nightly",
    buildDate: "2023-06-10T00:19:16.378Z",
    commitHash: "a621472336dc27f100c57e3f25cfa3f9a26592f1",
};
